<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint0_linear_25095_21588)"/>
    <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" stroke="url(#paint1_linear_25095_21588)" stroke-width="1.5"/>
    <path d="M6.32143 13V12H5.78572C5.63393 12 5.50679 11.952 5.40429 11.856C5.30179 11.76 5.25036 11.6413 5.25 11.5C5.24964 11.3587 5.30107 11.24 5.40429 11.144C5.5075 11.048 5.63464 11 5.78572 11H6.32143V10H5.78572C5.63393 10 5.50679 9.952 5.40429 9.856C5.30179 9.76 5.25036 9.64133 5.25 9.5C5.24964 9.35867 5.30107 9.24 5.40429 9.144C5.5075 9.048 5.63464 9 5.78572 9H6.32143V5C6.32143 4.85833 6.37286 4.73967 6.47572 4.644C6.57857 4.54833 6.70572 4.50033 6.85714 4.5H9.80357C10.625 4.5 11.3214 4.76667 11.8929 5.3C12.4643 5.83333 12.75 6.48333 12.75 7.25C12.75 8.01667 12.4643 8.66667 11.8929 9.2C11.3214 9.73333 10.625 10 9.80357 10H7.39286V11H9C9.15179 11 9.27911 11.048 9.38197 11.144C9.48482 11.24 9.53607 11.3587 9.53572 11.5C9.53536 11.6413 9.48393 11.7602 9.38143 11.8565C9.27893 11.9528 9.15179 12.0007 9 12H7.39286V13C7.39286 13.1417 7.34143 13.2605 7.23857 13.3565C7.13572 13.4525 7.00857 13.5003 6.85714 13.5C6.70572 13.4997 6.57857 13.4517 6.47572 13.356C6.37286 13.2603 6.32143 13.1417 6.32143 13ZM7.39286 9H9.80357C10.3214 9 10.7634 8.82917 11.1295 8.4875C11.4955 8.14583 11.6786 7.73333 11.6786 7.25C11.6786 6.76667 11.4955 6.35417 11.1295 6.0125C10.7634 5.67083 10.3214 5.5 9.80357 5.5H7.39286V9Z" fill="white" stroke="white" stroke-width="0.5"/>
    <defs>
      <linearGradient id="paint0_linear_25095_21588" x1="18" y1="-4.85662e-07" x2="0.446612" y2="18.4255" gradientUnits="userSpaceOnUse">
        <stop stop-color="#547AFF"/>
        <stop offset="1" stop-color="#413DFF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_25095_21588" x1="18" y1="-4.85662e-07" x2="0.446612" y2="18.4255" gradientUnits="userSpaceOnUse">
        <stop stop-color="#547AFF"/>
        <stop offset="1" stop-color="#413DFF"/>
      </linearGradient>
    </defs>
  </svg>
</template>
